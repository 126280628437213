<template>
  <v-row>
    <template v-if="fundingInstructions.length > 0"> 
      <v-col
        v-for="(fundingInstruction, index) in this.fundingInstructions"
        :key="index"
        :cols="cols"
      >
        <v-card elevation="2">
          <v-card-title>
            <v-icon class="mr-2">
              fas fa-university
            </v-icon> IBAN
            <v-spacer></v-spacer>
            <flag :iso="fundingInstruction?.bank_transfer?.country"></flag>
          </v-card-title>
          <v-card-text v-if="fundingInstruction?.bank_transfer?.financial_addresses">
            <div v-for="(financialAddresse, index) in fundingInstruction?.bank_transfer?.financial_addresses" :key="index">
              <table v-if="financialAddresse?.iban">
                <tr>
                  <td class="pr-3">
                    {{ $t('ibanBic') }}
                  </td><td>{{ financialAddresse?.iban?.bic }}</td>
                </tr>
                <tr>
                  <td class="pr-3">
                    IBAN
                  </td><td>{{ financialAddresse?.iban?.iban }}</td>
                </tr>
                <tr>
                  <td class="pr-3">
                    {{ $t('ibanCountry') }}
                  </td><td>{{ financialAddresse?.iban?.country }}</td>
                </tr>
              </table>
              <v-alert
                v-else
                outlined
                text
                type="error"
              >
                {{ $t('pleaseContactSupport') }}
              </v-alert>
              <v-divider class="my-2" v-if="fundingInstruction?.bank_transfer?.financial_addresses.length > 1 && index !== fundingInstruction?.bank_transfer?.financial_addresses.length - 1"></v-divider>
            </div>
          </v-card-text>
          <v-card-text v-else>
            <v-alert
              outlined
              text
              type="error"
            >
              {{ $t('pleaseContactSupport') }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
    <v-col
      v-else
    >
      <SupportEmail
        :pre-subject="`[${$appConfig.supplier.name}] [Client - ${brand.name}]`"
        subject="Ajouter un compte bancaire virtuel pour ce client"
        :body="`Bonjour, 
Serait-il possible d'ajouter un compte bancaire virtuel pour ce client afin qu'il puisse régler ses commandes par virement bancaire.
Merci d'avance.

${$appConfig?.user?.firstname} ${$appConfig?.user?.lastname}
`"
        :display-button="true"
        ref="supportAddVirtualAccount"
      ></SupportEmail>
    </v-col>
  </v-row>
</template>

<script>
import {
  EventBus,
  ApiErrorParser,
} from '@cloudmanufacturingtechnologies/portal-components';

import SupportEmail from '../supportEmail/SupportEmail';
const i18nData = require('./bankFundingInstructionsSupplier.i18n.json');

export default {
  name: 'BankTransferInstructionsSupplier',
  components: {
    SupportEmail
  },
  props: {
    cols: {
      type: String,
      default: '4'
    },
    brand: {
      type: String,
      required: true,
    }
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      fundingInstructions: null
    };
  },
  created() {
    if (!this.$appConfig.brand) {
      EventBus.$emit('reloadBrand');
    }

    this.getBrandStripeFundingInstructions();
  },
  methods: {
    getBrandStripeFundingInstructions() {
      /**
       * GET SAVED PAYMENT METHODS
       */
      this.$apiInstance.getSupplierBrandStripeFundingInstructions(this.$appConfig.supplier._id, this.brand._id)
        .then(
          (fundingInstructionsData) => {
            this.fundingInstructions = fundingInstructionsData;
          },
          (error) => {
            this.fundingInstructions = [];
          }
        );
    }
  },
};
</script>
