<template lang="html" src="./pageQuote.template.vue"></template>

<style lang="scss" src="./pageQuote.scss"></style>

<script>
import { AddCustomLine, ApiErrorParser, BeelseSupplierQuote, ModifyCustomLine, ModifyItemLine, ModifyDelivery } from '@cloudmanufacturingtechnologies/portal-components';

import QuoteValidation from '../../components/quoteValidation/QuoteValidation';

const i18nData = require('./pageQuote.i18n');

export default {
  name: 'PageQuote',
  components: {
    AddCustomLine,
    BeelseSupplierQuote,
    ModifyCustomLine,
    ModifyItemLine,
    ModifyDelivery,
    QuoteValidation
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      quote: null,
      pdfData: null,
      loading: false,
      loadingQuotePDF: false,
      addDispatchCustomLineDialog: false,
      modifyDispatchCustomLineDialog: false,
      customLineToModify: null,
      modifyDispatchItemDialog: false,
      itemToModify: null,
      modifyDispatchDeliveryDialog: false
    };
  },
  created() {
    this.getSupplierQuote();
  },
  methods: {
    getSupplierQuote() {
      this.loading = true;
      this.$apiInstance.getSupplierQuote(
        this.$route.params.supplierUUID, 
        this.$route.params.quoteUUID
      )
        .then(
          data => {
            this.quote = data;
          },
          (error) => {
            this.$notification.notify('ERROR', ApiErrorParser.parse(error));
          })
        .finally(() => {
          this.loading = false;
        });
    },
    getQuotePDF() {
      if(!this.quotePDFData) {
        this.loadingQuotePDF = true;
        this.$apiInstance.getSupplierQuotePDF(
          this.$route.params.supplierUUID,
          this.$route.params.quoteUUID
        ).then(
          pdf => {
            this.pdfData = pdf.data;
            this.$refs.quotePDFRef.href = `data:application/pdf;base64,${this.pdfData}`;
            setTimeout(() => {
              this.$refs.quotePDFRef.click();
            }, 250);
          },
          (error) => {
            this.$notification.notify('ERROR', ApiErrorParser.parse(error));
          }
        ).finally(() => {
          this.loadingQuotePDF = false;
        });
      } else {
        this.$refs.quotePDFRef.click();
      }
    },
    openAddDispatchCustomLineDialog() {
      this.addDispatchCustomLineDialog = true;
    },
    closeAddDispatchCustomLineDialog() {
      this.addDispatchCustomLineDialog = false;
    },
    openModifyDispatchCustomLineDialog(supplierUUID, customLineUUID) {
      this.customLineToModify = this.quote.dispatch.customLines.find(cL => {return cL._id === customLineUUID;});
      this.modifyDispatchCustomLineDialog = true;
    },
    closeModifyDispatchCustomLineDialog() {
      this.modifyDispatchCustomLineDialog = false;
    },
    openModifyDispatchItemDialog(supplierUUID, partUUID) {
      this.itemToModify = this.quote.dispatch.items.find(i => {return i.part._id.toString() === partUUID;});
      this.modifyDispatchItemDialog = true;
    },
    closeModifyDispatchItemDialog() {
      this.modifyDispatchItemDialog = false;
    },
    openModifyDispatchDeliveryDialog(supplierUUID) {
      this.modifyDispatchDeliveryDialog = true;
    },
    closeModifyDispatchDeliveryDialog() {
      this.modifyDispatchDeliveryDialog = false;
    },
    addDispatchCustomLine(newCustomLine) {
      const addSupplierQuoteDispatchCustomLineBody = new this.$BcmModel.AddSupplierQuoteDispatchCustomLineBody(
        newCustomLine.description,
        Number(newCustomLine.quantity),
        newCustomLine.optional,
        newCustomLine.selected,
        Number(newCustomLine.purchase.price ?? 0),
        Number(newCustomLine.purchase.vatPercentage)
      );

      this.$apiInstance.addSupplierQuoteDispatchCustomLine(
        this.$route.params.supplierUUID,
        this.$route.params.quoteUUID,
        addSupplierQuoteDispatchCustomLineBody
      ).then((data) => {
        this.$notification.notify('SUCCESS',this.$t('AddedLine'));
        this.addDispatchCustomLineDialog = false;
      }, (error) => {
        this.$notification.notify('DANGER', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getSupplierQuote();
      });
    },
    modifyDispatchCustomLine(customLineUUID, modifiedCustomLineObject) {
      const modifySupplierQuoteDispatchCustomLineBody = new this.$BcmModel.ModifySupplierQuoteDispatchCustomLineBody(
        modifiedCustomLineObject.description,
        Number(modifiedCustomLineObject.quantity),
        modifiedCustomLineObject.optional,
        modifiedCustomLineObject.selected,
        Number(modifiedCustomLineObject.purchase.price ?? 0),
        Number(modifiedCustomLineObject.purchase.vatPercentage)
      );

      this.$apiInstance.modifySupplierQuoteDispatchCustomLine(
        this.$route.params.supplierUUID,
        this.$route.params.quoteUUID,
        customLineUUID,
        modifySupplierQuoteDispatchCustomLineBody
      ).then((data) => {
        this.$notification.notify('SUCCESS',this.$t('ModifiedLine'));
        this.modifyDispatchCustomLineDialog = false;
      }, (error) => {
        this.$notification.notify('DANGER', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getSupplierQuote();
      });
    },
    deleteDispatchCustomLine(customLineUUID) {
      this.$apiInstance.deleteSupplierQuoteDispatchCustomLine(
        this.$route.params.supplierUUID,
        this.$route.params.quoteUUID,
        customLineUUID
      ).then((data) => {
        this.$notification.notify('SUCCESS',this.$t('DeleteDispatchLine'));
        this.modifyDispatchCustomLineDialog = false;
      }, (error) => {
        this.$notification.notify('DANGER', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getSupplierQuote();
      });
    },
    modifyQuoteDispatchStatus(status) {
      const modifySupplierQuoteDispatchStatusBody = new this.$BcmModel.ModifySupplierQuoteDispatchStatusBody(
        status
      );

      this.$apiInstance.modifySupplierQuoteDispatchStatus(
        this.$route.params.supplierUUID,
        this.$route.params.quoteUUID,
        modifySupplierQuoteDispatchStatusBody
      ).then(data => {
        if(data.dispatch.status === 'READY') {
          this.$notification.notify('SUCCESS', this.$t('QuoteHasBeenSentToTheCustomer'));
        } else if(data.dispatch.status === 'NEW') {
          this.$notification.notify('SUCCESS', this.$t('YouCanNowEditTheQuote'));
        }
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getSupplierQuote();
      });
    },
    modifyQuoteExpirationDate(expirationDate) {
      const modifySupplierQuoteExpirationDateBody = new this.$BcmModel.ModifySupplierQuoteExpirationDateBody(
        expirationDate
      );

      this.$apiInstance.modifySupplierQuoteExpirationDate(
        this.$route.params.supplierUUID,
        this.$route.params.quoteUUID,
        modifySupplierQuoteExpirationDateBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('QuoteExpirationDateHasBeenModified'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getSupplierQuote();
      });
    },
    modifyQuoteDispatchItem(partUUID, modifiedItem) {
      const modifySupplierQuoteDispatchItemPurchasePriceBody = new this.$BcmModel.ModifySupplierQuoteDispatchItemPurchasePriceBody(
        Number(modifiedItem.purchase.price),
        Number(modifiedItem.purchase.vatPercentage)
      );

      this.$apiInstance.modifySupplierQuoteDispatchItemPurchasePrice(
        this.$route.params.supplierUUID,
        this.$route.params.quoteUUID,
        partUUID,
        modifySupplierQuoteDispatchItemPurchasePriceBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('ItemPriceModified'));
        this.closeModifyDispatchItemDialog();
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getSupplierQuote();
      });
    },
    modifyQuoteDispatchDelivery(supplierUUID, modifiedDelivery) {
      const modifySupplierQuoteDispatchPurchaseDeliveryPriceBody = new this.$BcmModel.ModifySupplierQuoteDispatchPurchaseDeliveryPriceBody(
        Number(modifiedDelivery.purchase.price),
        Number(modifiedDelivery.purchase.vatPercentage)
      );

      this.$apiInstance.modifySupplierQuoteDispatchPurchaseDeliveryPrice(
        supplierUUID,
        this.$route.params.quoteUUID,
        modifySupplierQuoteDispatchPurchaseDeliveryPriceBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('DeliveryPriceModified'));
        this.closeModifyDispatchDeliveryDialog();
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getSupplierQuote();
      });
    },
    modifyQuoteDispatchPublicNote(supplierUUID, publicNote) {
      const modifySupplierQuoteDispatchPublicNoteBody = new this.$BcmModel.ModifySupplierQuoteDispatchPublicNoteBody(
        publicNote
      );

      this.$apiInstance.modifySupplierQuoteDispatchPublicNote(
        supplierUUID,
        this.$route.params.quoteUUID,
        modifySupplierQuoteDispatchPublicNoteBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('NoteModified'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getSupplierQuote();
      });
    },
    modifyQuoteDispatchDeliveryNote(supplierUUID, deliveryNote) {
      const modifySupplierQuoteDispatchDeliveryNoteBody = new this.$BcmModel.ModifySupplierQuoteDispatchDeliveryNoteBody(
        deliveryNote
      );

      this.$apiInstance.modifySupplierQuoteDispatchDeliveryNote(
        supplierUUID,
        this.$route.params.quoteUUID,
        modifySupplierQuoteDispatchDeliveryNoteBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('NoteModified'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getSupplierQuote();
      });
    }
  },
};
</script>
