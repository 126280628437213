var render = function () {
  var _vm$$appConfig, _vm$$appConfig$user, _vm$$appConfig2, _vm$$appConfig2$user;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_vm.fundingInstructions.length > 0 ? _vm._l(this.fundingInstructions, function (fundingInstruction, index) {
    var _fundingInstruction$b, _fundingInstruction$b2, _fundingInstruction$b3;

    return _c('v-col', {
      key: index,
      attrs: {
        "cols": _vm.cols
      }
    }, [_c('v-card', {
      attrs: {
        "elevation": "2"
      }
    }, [_c('v-card-title', [_c('v-icon', {
      staticClass: "mr-2"
    }, [_vm._v(" fas fa-university ")]), _vm._v(" IBAN "), _c('v-spacer'), _c('flag', {
      attrs: {
        "iso": fundingInstruction === null || fundingInstruction === void 0 ? void 0 : (_fundingInstruction$b = fundingInstruction.bank_transfer) === null || _fundingInstruction$b === void 0 ? void 0 : _fundingInstruction$b.country
      }
    })], 1), fundingInstruction !== null && fundingInstruction !== void 0 && (_fundingInstruction$b2 = fundingInstruction.bank_transfer) !== null && _fundingInstruction$b2 !== void 0 && _fundingInstruction$b2.financial_addresses ? _c('v-card-text', _vm._l(fundingInstruction === null || fundingInstruction === void 0 ? void 0 : (_fundingInstruction$b3 = fundingInstruction.bank_transfer) === null || _fundingInstruction$b3 === void 0 ? void 0 : _fundingInstruction$b3.financial_addresses, function (financialAddresse, index) {
      var _financialAddresse$ib, _financialAddresse$ib2, _financialAddresse$ib3, _fundingInstruction$b4, _fundingInstruction$b5;

      return _c('div', {
        key: index
      }, [financialAddresse !== null && financialAddresse !== void 0 && financialAddresse.iban ? _c('table', [_c('tr', [_c('td', {
        staticClass: "pr-3"
      }, [_vm._v(" " + _vm._s(_vm.$t('ibanBic')) + " ")]), _c('td', [_vm._v(_vm._s(financialAddresse === null || financialAddresse === void 0 ? void 0 : (_financialAddresse$ib = financialAddresse.iban) === null || _financialAddresse$ib === void 0 ? void 0 : _financialAddresse$ib.bic))])]), _c('tr', [_c('td', {
        staticClass: "pr-3"
      }, [_vm._v(" IBAN ")]), _c('td', [_vm._v(_vm._s(financialAddresse === null || financialAddresse === void 0 ? void 0 : (_financialAddresse$ib2 = financialAddresse.iban) === null || _financialAddresse$ib2 === void 0 ? void 0 : _financialAddresse$ib2.iban))])]), _c('tr', [_c('td', {
        staticClass: "pr-3"
      }, [_vm._v(" " + _vm._s(_vm.$t('ibanCountry')) + " ")]), _c('td', [_vm._v(_vm._s(financialAddresse === null || financialAddresse === void 0 ? void 0 : (_financialAddresse$ib3 = financialAddresse.iban) === null || _financialAddresse$ib3 === void 0 ? void 0 : _financialAddresse$ib3.country))])])]) : _c('v-alert', {
        attrs: {
          "outlined": "",
          "text": "",
          "type": "error"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('pleaseContactSupport')) + " ")]), (fundingInstruction === null || fundingInstruction === void 0 ? void 0 : (_fundingInstruction$b4 = fundingInstruction.bank_transfer) === null || _fundingInstruction$b4 === void 0 ? void 0 : _fundingInstruction$b4.financial_addresses.length) > 1 && index !== (fundingInstruction === null || fundingInstruction === void 0 ? void 0 : (_fundingInstruction$b5 = fundingInstruction.bank_transfer) === null || _fundingInstruction$b5 === void 0 ? void 0 : _fundingInstruction$b5.financial_addresses.length) - 1 ? _c('v-divider', {
        staticClass: "my-2"
      }) : _vm._e()], 1);
    }), 0) : _c('v-card-text', [_c('v-alert', {
      attrs: {
        "outlined": "",
        "text": "",
        "type": "error"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('pleaseContactSupport')) + " ")])], 1)], 1)], 1);
  }) : _c('v-col', [_c('SupportEmail', {
    ref: "supportAddVirtualAccount",
    attrs: {
      "pre-subject": "[".concat(_vm.$appConfig.supplier.name, "] [Client - ").concat(_vm.brand.name, "]"),
      "subject": "Ajouter un compte bancaire virtuel pour ce client",
      "body": "Bonjour, \nSerait-il possible d'ajouter un compte bancaire virtuel pour ce client afin qu'il puisse r\xE9gler ses commandes par virement bancaire.\nMerci d'avance.\n\n".concat((_vm$$appConfig = _vm.$appConfig) === null || _vm$$appConfig === void 0 ? void 0 : (_vm$$appConfig$user = _vm$$appConfig.user) === null || _vm$$appConfig$user === void 0 ? void 0 : _vm$$appConfig$user.firstname, " ").concat((_vm$$appConfig2 = _vm.$appConfig) === null || _vm$$appConfig2 === void 0 ? void 0 : (_vm$$appConfig2$user = _vm$$appConfig2.user) === null || _vm$$appConfig2$user === void 0 ? void 0 : _vm$$appConfig2$user.lastname, "\n"),
      "display-button": true
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }