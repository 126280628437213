var render = function () {
  var _vm$quote, _vm$quote2, _vm$quote3, _vm$quote3$dispatch, _vm$quote3$dispatch$i, _vm$quote4, _vm$quote4$dispatch, _vm$quote5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-quote"
  }, [_c('v-row', [_c('v-col', [_c('span', {
    staticClass: "display-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("Quote")) + " - " + _vm._s((_vm$quote = _vm.quote) === null || _vm$quote === void 0 ? void 0 : _vm$quote.quoteNumber) + " ")])]), _c('v-col', {
    attrs: {
      "align": "right"
    }
  }, [_c('a', {
    ref: "quotePDFRef",
    staticClass: "d-none",
    attrs: {
      "download": "".concat(_vm.$t('Quote'), "_CMT_").concat((_vm$quote2 = _vm.quote) === null || _vm$quote2 === void 0 ? void 0 : _vm$quote2.quoteNumber)
    }
  }), !((_vm$quote3 = _vm.quote) !== null && _vm$quote3 !== void 0 && (_vm$quote3$dispatch = _vm$quote3.dispatch) !== null && _vm$quote3$dispatch !== void 0 && (_vm$quote3$dispatch$i = _vm$quote3$dispatch.items) !== null && _vm$quote3$dispatch$i !== void 0 && _vm$quote3$dispatch$i.some(function (i) {
    return i.purchase.price === null;
  })) ? _c('v-btn', {
    staticClass: "text-none white--text mr-2",
    attrs: {
      "color": "orange",
      "loading": _vm.loadingQuotePDF,
      "ripple": false,
      "disabled": !_vm.quote
    },
    on: {
      "click": _vm.getQuotePDF
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-file-pdf ")]), _vm._v(" " + _vm._s(_vm.$t('Quote')) + " ")], 1) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-divider')], 1)], 1), _c('v-row', {
    staticClass: "flex-wrap-reverse justify-space-around"
  }, [_c('v-col', [_vm.quote ? _c('BeelseSupplierQuote', {
    attrs: {
      "quote": _vm.quote,
      "admin": !_vm.quote.public,
      "editable": ((_vm$quote4 = _vm.quote) === null || _vm$quote4 === void 0 ? void 0 : (_vm$quote4$dispatch = _vm$quote4.dispatch) === null || _vm$quote4$dispatch === void 0 ? void 0 : _vm$quote4$dispatch.status) === 'NEW'
    },
    on: {
      "modifyDispatchItem": _vm.openModifyDispatchItemDialog,
      "modifyDispatchDelivery": _vm.openModifyDispatchDeliveryDialog,
      "modifyDispatchPublicNote": _vm.modifyQuoteDispatchPublicNote,
      "modifyDispatchDeliveryNote": _vm.modifyQuoteDispatchDeliveryNote,
      "addDispatchCustomLine": _vm.openAddDispatchCustomLineDialog,
      "modifyDispatchCustomLine": _vm.openModifyDispatchCustomLineDialog
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.quote ? _c('QuoteValidation', {
    attrs: {
      "quote": _vm.quote
    },
    on: {
      "modifyQuoteDispatchStatus": _vm.modifyQuoteDispatchStatus,
      "modifyQuoteExpirationDate": _vm.modifyQuoteExpirationDate
    }
  }) : _vm._e()], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "1400px"
    },
    model: {
      value: _vm.addDispatchCustomLineDialog,
      callback: function callback($$v) {
        _vm.addDispatchCustomLineDialog = $$v;
      },
      expression: "addDispatchCustomLineDialog"
    }
  }, [_vm.addDispatchCustomLineDialog ? _c('AddCustomLine', {
    attrs: {
      "display-close-buttons": true,
      "specify-purchase-price": true,
      "specify-selling-price": false
    },
    on: {
      "addCustomLine": _vm.addDispatchCustomLine,
      "close": _vm.closeAddDispatchCustomLineDialog
    }
  }) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "max-width": "1400px"
    },
    model: {
      value: _vm.modifyDispatchCustomLineDialog,
      callback: function callback($$v) {
        _vm.modifyDispatchCustomLineDialog = $$v;
      },
      expression: "modifyDispatchCustomLineDialog"
    }
  }, [_vm.customLineToModify && _vm.modifyDispatchCustomLineDialog ? _c('ModifyCustomLine', {
    attrs: {
      "custom-line": _vm.customLineToModify,
      "display-close-buttons": true,
      "specify-purchase-price": true,
      "specify-selling-price": false
    },
    on: {
      "close": _vm.closeModifyDispatchCustomLineDialog,
      "modifyCustomLine": _vm.modifyDispatchCustomLine,
      "deleteCustomLine": _vm.deleteDispatchCustomLine
    }
  }) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "max-width": "1000px"
    },
    model: {
      value: _vm.modifyDispatchItemDialog,
      callback: function callback($$v) {
        _vm.modifyDispatchItemDialog = $$v;
      },
      expression: "modifyDispatchItemDialog"
    }
  }, [_vm.itemToModify ? _c('ModifyItemLine', {
    attrs: {
      "item": _vm.itemToModify,
      "display-close-buttons": true,
      "specify-purchase-price": true,
      "specify-selling-price": false
    },
    on: {
      "modifyItemLine": _vm.modifyQuoteDispatchItem,
      "close": _vm.closeModifyDispatchItemDialog
    }
  }) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "max-width": "600px"
    },
    model: {
      value: _vm.modifyDispatchDeliveryDialog,
      callback: function callback($$v) {
        _vm.modifyDispatchDeliveryDialog = $$v;
      },
      expression: "modifyDispatchDeliveryDialog"
    }
  }, [_vm.quote ? _c('ModifyDelivery', {
    attrs: {
      "dispatch": (_vm$quote5 = _vm.quote) === null || _vm$quote5 === void 0 ? void 0 : _vm$quote5.dispatch,
      "display-close-buttons": true,
      "specify-purchase-price": true,
      "specify-selling-price": false
    },
    on: {
      "modifyDelivery": _vm.modifyQuoteDispatchDelivery,
      "close": _vm.closeModifyDispatchDeliveryDialog
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }